import { useEffect, useMemo } from 'react';

import { objectStoreApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { ReportRun } from '@import-io/types';
import { CrawlRunState } from '@import-io/types';
import type { InfiniteData, QueryKey } from '@tanstack/query-core';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';

export const REPORT_RUNS_LIST_KEY = 'reportRuns';
export const LATEST_REPORT_RUN_KEY = 'latestReportRun';
export const REPORT_RUN_KEY = 'reportRun';

const PAGE_SIZE = 30;

// get paginated data for report runs list
const useReportRuns = (reportId: string) => {
  return useInfiniteQuery<ReportRun[], Error, InfiniteData<ReportRun>, QueryKey, number>({
    queryKey: [REPORT_RUNS_LIST_KEY, reportId],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(PAGE_SIZE)
        .setPageNumber(pageParam)
        .setSortDesc(true)
        .addEqFilter('reportId', reportId)
        .build();
      return objectStoreApi.reportRun.query(query);
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length === PAGE_SIZE ? allPages.length + 1 : undefined;
    },
    enabled: isPresent(reportId),
  });
};

// Poll the first item of the list every 5 seconds to detect new report runs
export const useLatestReportRun = (reportId: string) => {
  return useQuery<ReportRun | null, Error>({
    queryKey: [LATEST_REPORT_RUN_KEY, reportId],
    queryFn: async () => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(1)
        .setPageNumber(1)
        .setSortDesc(true)
        .addEqFilter('reportId', reportId)
        .build();
      const results = await objectStoreApi.reportRun.query(query);
      return results[0] ?? null;
    },
    enabled: isPresent(reportId),
    refetchInterval: 5000,
  });
};

const usePollReportRunById = (reportRunId?: string) => {
  return useQuery<ReportRun, Error>({
    queryKey: [REPORT_RUN_KEY, reportRunId],
    queryFn: () => {
      return objectStoreApi.reportRun.get(reportRunId!) as Promise<ReportRun>;
    },
    enabled: isPresent(reportRunId),
    refetchInterval: 1000,
  });
};

const ACTIVE_REPORT_RUN_STATES: Set<string> = new Set<string>([
  CrawlRunState.PENDING,
  CrawlRunState.STARTED,
  CrawlRunState.COLLATING,
]);

export const useReportRunsList = (reportId: string, onReportRunAdded?: (reportRun: ReportRun) => void) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useReportRuns(reportId);
  const latestQuery = useLatestReportRun(reportId);
  const reportRuns: ReportRun[] = useMemo(() => {
    return data?.pages.flat() ?? [];
  }, [data]);

  const activeReportRunId = useMemo(() => {
    const last = reportRuns[0];
    if (isPresent(last) && ACTIVE_REPORT_RUN_STATES.has(last?.status)) {
      return last.guid;
    }
    return undefined;
  }, [reportRuns]);

  const activeReportRunQuery = usePollReportRunById(activeReportRunId);

  const reportRunAdded =
    isPresent(latestQuery.data) && (reportRuns.length === 0 || latestQuery.data.guid !== reportRuns[0]?.guid);

  useEffect(() => {
    const latestReportRun = latestQuery.data;
    if (!reportRunAdded || !isPresent(latestReportRun)) {
      return;
    }

    // Update the report runs list
    queryClient.setQueryData<InfiniteData<ReportRun[]>>([REPORT_RUNS_LIST_KEY, reportId], (oldData) => {
      if (!isPresent(oldData)) {
        return oldData;
      }
      const [firstPage = []] = oldData.pages;
      return {
        ...oldData,
        pages: [[latestReportRun, ...firstPage]],
        pageParams: oldData.pageParams.slice(0, 1),
      };
    });
    void queryClient.invalidateQueries({
      queryKey: [REPORT_RUNS_LIST_KEY, reportId],
      refetchType: 'active',
    });
    onReportRunAdded?.(latestReportRun);
  }, [reportRunAdded, reportId, queryClient, latestQuery.data, onReportRunAdded]);

  // update active ReportRun
  useEffect(() => {
    if (!isPresent(activeReportRunQuery.data)) {
      return;
    }
    queryClient.setQueryData<InfiniteData<ReportRun[]>>([REPORT_RUNS_LIST_KEY, reportId], (oldData) => {
      if (!oldData) return oldData;
      const newPages = oldData.pages.map((page) =>
        page.map((reportRun) => (reportRun.guid === activeReportRunQuery.data!.guid ? activeReportRunQuery.data! : reportRun)),
      );
      return {
        ...oldData,
        pages: newPages,
      };
    });
    queryClient.setQueryData<ReportRun>([LATEST_REPORT_RUN_KEY, reportId], activeReportRunQuery.data!);
  }, [reportRuns, activeReportRunQuery.data, queryClient, reportId]);

  return {
    reportRuns: reportRuns,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage: isFetchingNextPage,
    isPending: isPending,
  };
};

export const useIsReportRunning = (reportId: string): boolean => {
  const { data: latestReportRun } = useLatestReportRun(reportId);

  return useMemo(() => {
    if (!isPresent(latestReportRun)) {
      return false;
    }
    return ACTIVE_REPORT_RUN_STATES.has(latestReportRun.status) && latestReportRun.status !== CrawlRunState.COLLATING;
  }, [latestReportRun]);
};
