import type { CSSProperties, PropsWithChildren } from 'react';

import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import { isPresent } from '@import-io/typeguards';
import type { PopoverProps } from 'antd/lib/popover';
import Popover from 'antd/lib/popover';
import Space from 'antd/lib/space';

interface DropdownPopoverProps extends PropsWithChildren<PopoverProps> {
  readonly childrenStyle?: CSSProperties;
  readonly button?: JSX.Element;
}

const defaultChildrenStyle: CSSProperties = { cursor: 'pointer' };

const ButtonPopover = ({
  children,
  childrenStyle = defaultChildrenStyle,
  button,
  trigger,
  ...restProps
}: DropdownPopoverProps): JSX.Element => {
  const realTrigger = isPresent(trigger) ? trigger : 'click';
  return (
    <Popover trigger={realTrigger} {...restProps}>
      {children}
      {button ? button : <CaretDownOutlined />}
    </Popover>
  );
};

export default ButtonPopover;
