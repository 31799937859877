import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import type { ResponseError } from '@import-io/js-sdk';
import { objectStoreApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Report, ReportType } from '@import-io/types/report-types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import type { AppDispatch } from 'app/app-types';
import { selectReport } from 'app/dash-old/actions/reports';
import { REPORT_TYPES } from 'app/dash-old/lib/constants/reports';
import { fetchFirstReportApi, fetchReportsApi } from 'features/reports/common/api/report-list-api';
import { GET_REPORT_QUERY_KEY } from 'features/reports/common/reports-constants';
import { REPORT_LIST_QUERY_KEY } from 'features/reports/list/report-list-constants';
import { selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';

const initialFeatures = [];

const selectReportTypesFilter = (): ReportType[] | null => {
  const subscription = selectSubscriptionQueryData();
  const subscriptionFeatures = isPresent(subscription) ? subscription.features : initialFeatures;
  const availableReportTypes = REPORT_TYPES.filter(({ features }) => subscriptionFeatures.includes(features.permission)).map(
    ({ slug }) => slug,
  );

  // no filter if all report types are available
  return availableReportTypes.length === REPORT_TYPES.length ? null : availableReportTypes;
};

export const useReports = (): UseQueryResult<Report[], ResponseError> => {
  const typesFilter = selectReportTypesFilter();

  const q = useQuery<Report[], ResponseError>({
    queryKey: [REPORT_LIST_QUERY_KEY, typesFilter],
    queryFn: () => fetchReportsApi(typesFilter),
    refetchOnMount: false,
  });

  const { error } = q;

  useEffect(() => {
    if (error) {
      console.log('Fetch reports error:', error);
    }
  }, [error]);

  return q;
};

export const useFetchFirstReport = (): UseQueryResult<Report | undefined | null, ResponseError> => {
  return useQuery({
    queryKey: ['firstReport'],
    queryFn: async () => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageNumber(1)
        .setPageLimit(1)
        .setSortDesc(true)
        .setShowArchived(false)
        .setShowMine(true)
        .build();

      const results = await objectStoreApi.report.query(query);

      return results[0] ?? null;
    },
  });
};
