// Function to register the service worker
import { logout } from 'features/user/auth/user-auth-hooks';

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/requests-interceptor.worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);

          // Listen for updates to the service worker
          registration.addEventListener('updatefound', () => {
            const newWorker = registration.installing;
            newWorker?.addEventListener('statechange', () => {
              if (newWorker?.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // New update available, reload the page to activate the new service worker
                  window.location.reload();
                }
              }
            });
          });
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  } else {
    console.warn('Service Workers are not supported in this browser.');
  }
};

// Register the service worker
registerServiceWorker();

// Listen for messages from the service worker
navigator.serviceWorker.addEventListener('message', (event) => {
  const { type } = event.data;
  if (type === 'LOGOUT') {
    logout(); // Trigger the logout function
  }
});

// Listen for controller change to reload the page
let refreshing = false;
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});
